import { store } from '@/store';
import { requestPost } from '@/request/http/http';
import {
  GameApi,
  generalApi,
  SportServiceApi,
  LotteryApi,
  GameUserApi,
} from '@/request/http/index';
import { useCustomRouter } from '@/router/custRouter';
import { CATEGORY_BTN_TYPE } from '@/utils/consts';
import { ROUTE_LOGIN, clickToLoginWithBack } from '@/router/paths';

// 退出游戏拉回余额
export const requestExitGame = (show: boolean) => {
  return new Promise<void>((resolve) => {
    requestPost(
      GameUserApi.SwitchAllAmount,
      { currencyId: store.getters.currencyId },
      () => {
        resolve();
      },
      null,
      null,
      show
    );
  });
};

export const TPSPORT_ID = '999';
export enum GAME_TYPE {
  LOTTERY = '1',
  SPORT = '2',
  SLOT = '3',
}
// 获取游戏地址
export const requestLaunchGame = (game: any | null) => {
  return new Promise<any>((resolve, reject) => {
    const vo = game;
    if (
      vo.type == GAME_TYPE.SLOT ||
      (vo.type == GAME_TYPE.SPORT && vo?.id !== TPSPORT_ID)
    ) {
      const params = {
        gameId: vo.type == GAME_TYPE.SLOT ? vo.id : vo.glId, // 体育非TP用glId
        isMobile: 1, // pc端浏览器也是手机模式
      };
      requestPost(
        GameUserApi.GetGameUrl,
        params,
        (response) => {
          response && resolve(response);
        },
        (error) => {
          if (error.errorcode === 109020) {
            reject(error); // 进入转额
          }
        },
        null,
        true
      );
    } else {
      const identity = store.getters.userType.value;
      const { id, shortName } = store.getters.languageObj;
      const currencyId = store.getters.currencyId;
      let baseUrl = process.env.VUE_APP_BASE_API_2;
      const channel = location.hostname;
      if (!baseUrl || baseUrl.length == 0) {
        baseUrl = `https://${channel}`;
      }

      if (vo.type == '1') {
        // 跳转彩票的地址写死
        const url = `${baseUrl}/cp-games/?gameId=${vo.id}&token=${store.state.token}&identity=${identity}&languageId=${id}&languageName=${shortName}&currencyId=${currencyId}&channel=wap-${channel}&playId=undefined`;
        resolve({ gameUrl: url });
      } else if (vo.type == '2') {
        // 跳转tp体育的地址写死
        // tpSportMinimumAccessAmount().then((response) => {
        // if (response?.data?.result) {
        const url = `${baseUrl}/betman#/hall/game?token=${store.state.token}&identity=${identity}&languageId=${id}&languageName=${shortName}&currencyId=${currencyId}&channel=wap-${channel}`;
        resolve({ gameUrl: url });
        // }
        // else {
        //   showToast("333");
        // }
        // });
      }
    }
  });
};

// 收藏游戏
export const requestCollectGame = (
  game: any,
  goCollect: boolean,
  callback: () => void
) => {
  if (!store.getters.loginSuccess) {
    useCustomRouter(null).push(ROUTE_LOGIN.path);
    return;
  }
  const params = {
    gameId: Number(game.id),
    type: Number(game.type),
  };
  const apiUrl = goCollect
    ? generalApi.AddFavorites
    : generalApi.DeleteFavorites;
  requestPost(apiUrl, params, () => {
    callback();
  });
};

export const hasTransferId = (game: any) => {
  if (game.type === GAME_TYPE.SLOT) {
    // 电子类型的
    return game.platformId;
  } else if (game.type === GAME_TYPE.SPORT && game.id !== TPSPORT_ID) {
    // game.id 999 TP体育，其它id是电子体育
    return game.id;
  }

  return null;
};

export const clickToStartLaunchGame = () => {
  if (!store.getters.loginSuccess) {
    clickToLoginWithBack({});
    return false;
  }

  return true;
};

// 获取游戏大厅列表
export const getCateData = (
  category_id: any,
  platformId: string,
  page: number,
  limit: number,
  callback: (value: any[], count: string) => void
) => {
  if (category_id == CATEGORY_BTN_TYPE.popular) {
    getGamePopular((list: any, total: string) => {
      callback(list, total);
    });
  } else if (category_id == CATEGORY_BTN_TYPE.sport) {
    getSportService((list: any, total: string) => {
      callback(list, total);
    });
  } else if (category_id == CATEGORY_BTN_TYPE.lottery) {
    getLotteryList(platformId, page, limit, (list: any, total: string) => {
      callback(list, total);
    });
  } else if (category_id == CATEGORY_BTN_TYPE.favorites) {
    getFavorites((list: any, total: string) => {
      callback(list, total);
    });
  } else if (category_id == CATEGORY_BTN_TYPE.bonus) {
    //彩金游戏列表
    getBonusGame(page, limit, (list: any, total: string) => {
      callback(list, total);
    });
  } else {
    let categoryId = '0';
    if (category_id == CATEGORY_BTN_TYPE.slot) categoryId = '3';
    else if (category_id == CATEGORY_BTN_TYPE.live) categoryId = '4';
    else if (category_id == CATEGORY_BTN_TYPE.cards) categoryId = '2';
    else if (category_id == CATEGORY_BTN_TYPE.fishing) categoryId = '1';
    getListByShowCategory(
      categoryId,
      platformId,
      page,
      limit,
      (list: any, total: string) => {
        callback(list, total);
      }
    );
  }
};

// 获取全部游戏
export const getGameNavigationList = () => {
  store.commit('games/changeCategoryId', {
    categoryId: CATEGORY_BTN_TYPE.total,
  });
  requestGameNavigationList();
};

export const requestGameNavigationList = () => {
  const params = {
    siteType: 2,
  };
  requestPost(generalApi.GetGameNavigationList, params, (response) => {
    if (response == null) return;
    const _categorysShow: any[] = []; //分类列表
    Object.keys(response).forEach((key: string) => {
      if (
        key == 'sport' ||
        key == 'popular' ||
        key == 'lottery' ||
        key == 'bonus'
      ) {
        if (key == 'sport') response[key].sort = 3;
        else if (key == 'popular') response[key].sort = 0;
        else if (key == 'lottery') response[key].sort = 4;
        else if (key == 'bonus') response[key].sort = 9;
        response[key].type = 3;
        response[key].name = `categorybtn.${key.toUpperCase()}`;
        response[key].categoryId =
          CATEGORY_BTN_TYPE[key as keyof typeof CATEGORY_BTN_TYPE];
        response[
          key
        ].icon = require(`@/assets/images/nb/category/${key}-color.svg`);
        _categorysShow.push(response[key]);
      } else {
        if (response[key] instanceof Array) {
          const platformList: any = [];
          response[key].forEach((item: any) => {
            item.type = 1;
            item.sort = 2;
            item.icon = store.getters.cdnUrl + item.icon;
            item.list.forEach((v: any) => {
              v.platformId = item.platformId;
            });
            if (item.bannerIcon) {
              platformList.push({
                id: item.platformId,
                icon: store.getters.cdnUrl + item.bannerIcon,
                name: item.name,
              });
            }
            _categorysShow.push(item);
          });
          _categorysShow.push({
            sort: 1,
            type: 2,
            list: platformList,
          });
        }
      }
    });
    // //保存游戏到本地
    store.dispatch('games/saveBtnList', {
      btnList: _categorysShow.sort((a: any, b: any) => a.sort - b.sort),
    });
  });
};

// 获取1捕鱼,2棋牌,3电子,4视讯类列表
export const getListByShowCategory = (
  categoryId: string,
  platformId: string,
  page: number,
  limit: number,
  callback: (list: any[], total: string) => void
) => {
  const params = {
    categoryId,
    platformId,
    limit,
    page,
    siteType: 2,
  };
  requestPost(
    GameApi.GetListByShowCategory,
    params,
    (response) => {
      // if (response == null || response.data == null) return
      const res = response as any | null;
      if (res == null) return;
      // //保存游戏到本地
      callback(res.list, res.total);
    },
    null,
    null,
    true
  );
};

// 请求热门游戏列表
export const getGamePopular = (
  callback: (list: any[], total: string) => void
) => {
  const params = {
    siteType: 2,
  };
  requestPost(
    generalApi.GetGamePopular,
    params,
    (response) => {
      const res = response as any | null;
      if (res == null) return;
      res.list.forEach((value: any) => {
        value['name'] = value.glName;
        value['isFavorite'] = value.favorites;
        value['id'] = value.gameId;
      });
      callback(res.list, res.total);
    },
    null,
    null,
    true
  );
};

// 请求收藏游戏列表
export const getFavorites = (
  callback: (list: any[], total: string) => void
) => {
  const params = {};
  requestPost(
    generalApi.GetFavorites,
    params,
    (response) => {
      const res = response as any | null;
      if (res == null) return;
      res.list.forEach((value: any) => {
        value['isFavorite'] = '1';
      });
      callback(res.list, res.total);
    },
    null,
    null,
    true
  );
};

// 请求体育游戏列表
export const getSportService = (
  callback: (list: any[], total: string) => void
) => {
  const params = {
    type: 'game',
  };
  requestPost(
    SportServiceApi.SportPlatformList,
    params,
    (response) => {
      const res = response as any | null;
      if (res == null) return;
      res.forEach((value: any) => {
        value['icon'] = value.promoImageUrl;
      });
      callback(res, res.length);
    },
    null,
    null,
    true
  );
};

// export const tpSportMinimumAccessAmount = () => {
//   return requestPostPromise(
//     SportServiceApi.MinimumAccessAmount,
//     { currencyId: store.getters.currencyId },
//     null,
//     true
//   );
// };

// 请求彩种展示分类列表
export const getLotteryList = (
  platformId: string,
  page: number,
  limit: number,
  callback: (list: any[], total: string) => void
) => {
  const params = {
    siteType: 2,
    id: platformId,
    page,
    limit,
  };
  requestPost(
    LotteryApi.GetLotteryList,
    params,
    (response) => {
      const res = response as any | null;
      if (res == null) return;
      callback(res.list, res.total);
    },
    null,
    null,
    true
  );
};

// 首页点击电子游戏平台跳转后请求的游戏列表
export const getList = (
  platformId: string,
  page: number,
  limit: number,
  callback: (list: any[], total: string) => void
) => {
  const params = {
    limit,
    page,
    platformId,
    siteType: 2,
  };
  requestPost(GameApi.GetList, params, (response) => {
    const res = response as any | null;
    if (res?.list && res?.list.length > 0) {
      callback(res.list, res.total);
    }
  });
};

// 请求彩金游戏列表
export const getBonusGame = (
  page: number,
  limit: number,
  callback: (list: any[], total: string) => void
) => {
  const params = {
    siteType: 2,
    page,
    limit,
  };
  requestPost(
    generalApi.GetBonusGame,
    params,
    (response) => {
      const res = response as any | null;
      if (res == null) return;
      callback(res.list, res.total);
    },
    null,
    null,
    true
  );
};
