import { GamesState } from '@/store/modules/games';
import { UserState } from '@/store/modules/user';
import { ActivitiesState } from '@/store/modules/activities';
import { MessagePopupBoxState } from '@/store/modules/messagePopupBox';
import { RedpackState } from '@/store/modules/redpack';
import { rebatesState } from '@/store/modules/rebates';

import {
  NBCurrencyItem,
  NBOAuthConfig,
  NBSiteConfig,
  NBCountryCode,
  BrandConfig,
  LanguageConfig,
  BrandActivate,
  BrandAboutUs,
  CustomerService,
  StaticBanner,
  GuideInstall,
} from '@/interface';
import { ChatRoomState } from './modules/chatRoom';

export interface State {
  hasNetwork: boolean;
  games?: GamesState;
  user?: UserState;
  activities?: ActivitiesState;
  messagePopupBox?: MessagePopupBoxState;
  menuOpened: boolean;
  serviceShow: boolean;
  siteConfig: NBSiteConfig | null;
  userType: string;
  token: string | null;
  oauthConfig: NBOAuthConfig[];
  currencyList: NBCurrencyItem[];
  brandConfig: BrandConfig | null;
  brandActivate: BrandActivate | null;
  brandAboutUs: BrandAboutUs | null;
  localeLangId: string | null;
  languageList: LanguageConfig[];
  smsConfig: NBCountryCode[];
  showSpinSidebar: boolean; //砍一刀活动的侧边展示
  customerConfig: CustomerService | null;
  staticBanner: StaticBanner[]; //静态广告
  redpack?: RedpackState;
  rebates?: rebatesState;
  showDownloadBar: boolean; //app下载栏显示状态
  bannerList: any[] | null;
  showPwa: boolean;
  chromiumReadyPwa: boolean;
  errorIp: string | null;
  systemMaintenance: boolean;
  guideInstall: GuideInstall | null | undefined; // undefined接口未通；null该渠道无数据；
  // guidePopupTime: any[] | null | undefined;
  footerPromoLastTime: number | null;
  chatRoom?: ChatRoomState; // 聊天室信息
}

const state: State = {
  hasNetwork: true,
  menuOpened: false,
  serviceShow: false,
  siteConfig: null,
  userType: '',
  token: null,
  oauthConfig: [],
  currencyList: [],
  brandConfig: null,
  brandActivate: null,
  brandAboutUs: null,
  localeLangId: null,
  languageList: [],
  smsConfig: [],
  showSpinSidebar: false,
  customerConfig: null,
  staticBanner: [],
  showDownloadBar: false,
  bannerList: null,
  showPwa: false,
  chromiumReadyPwa: false,
  errorIp: null,
  systemMaintenance: false,
  guideInstall: undefined,
  // guidePopupTime: null,
  footerPromoLastTime: null,
};

export default state;
