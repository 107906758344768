import { initializeApp } from 'firebase/app';
import {
  MessagePayload,
  Messaging,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import { onUnmounted, ref, watch } from 'vue';
import { BPush } from '@bpush/bpushsdk-web';
import { version } from '../../../package.json';
import store from '@/request/http/store';
import { isInWebview } from '@/utils/util';

/** firebase service worker js 位置 */
const messagingServiceWorkerPath = `${process.env.VUE_APP_BASE_API_2}/${process.env.VUE_APP_PROJECT_PATH}/firebase/firebase-messaging-sw.js`;

const firebaseConfig = {
  apiKey: 'AIzaSyC3zrJzvPzmIY_hZaSFHTWrrsj3vPfcxmo',
  authDomain: 'baifuandroid.firebaseapp.com',
  projectId: 'baifuandroid',
  storageBucket: 'baifuandroid.appspot.com',
  messagingSenderId: '42574203204',
  appId: '1:42574203204:web:c83c58f18f6fbf049def11',
  measurementId: 'G-SJL4LTKDDM',
};

const initFirebaseMessaging = () => {
  let messaging: Messaging = {
    app: {
      name: 'default',
      options: {},
      automaticDataCollectionEnabled: false,
    },
  };
  try {
    // webview 不支援時(service worker), 防止 web 因存取 firebase 而崩潰
    if (!isInWebview()) {
      const firebaseApp = initializeApp(firebaseConfig);
      messaging = getMessaging(firebaseApp);
    }
  } catch (e) {
    console.error(e);
  }
  return messaging;
};

/** 註冊 service worker 及取得 firebase token */
const useFCMToken = (messaging: Messaging) => {
  const token = ref<string | null>(null);
  const getFCMToken = async () => {
    try {
      console.log('🚀 ~ bPushLogic.ts:52 ~ useFCMToken ~ 2 ~ token:', token);
      // TODO: 第一次進網頁 未註冊 service worker 時, 也要能正常
      navigator.serviceWorker
        .register(messagingServiceWorkerPath)
        .then(async (reg) => {
          const fcmToken = await getToken(messaging, {
            vapidKey:
              'BKZiL_55dmtRidH4nn1CoJy2VczKm4XWROouVLuv2nZy1xpVWo1DHhUSYHpSY0vtnSpZ8YK10h3_qdETCRcn5J8',
            serviceWorkerRegistration: reg,
          });
          token.value = fcmToken;
        });
    } catch (error) {
      console.error('Notification bPush 获取FCM令牌失败:', error);
    }
  };
  getFCMToken();

  return {
    token,
  };
};

/** 註冊推送通知相關服務 */
export const useRegisterPush = () => {
  console.log('🚀 ~ bPushLogic.ts:94 ~ useRegisterPush ~ 1');
  const messaging = initFirebaseMessaging();
  const { token } = useFCMToken(messaging);
  const appKey = process.env.VUE_APP_BPUSH_KEY ?? ''; // "dd436241299741a00dfd692de82b85b9";
  const bundleID = 'com.baifu.bpushandroid';

  /** 處理接收到 firebase 推送通知的行為 */
  const observer = (payload: MessagePayload) => {
    console.log('🚀 ~ bPushLogic.ts:86 ~ observer ~ payload:', payload);
    const env = process.env.VUE_APP_ENV ?? 'dev';
    const prefix = ['dev', 'alpha'].includes(env) ? `[${env}]` : '';
    const notificationTitle = prefix + (payload?.notification?.title ?? '');
    const notificationOptions = {
      body: payload?.notification?.body ?? '',
      icon: '/nb_backend/nb-test/pwa/game/192.png',
    };
    navigator.serviceWorker
      .getRegistration(messagingServiceWorkerPath)
      .then((registration) => {
        registration?.showNotification(notificationTitle, notificationOptions);
      });
  };

  /** 停止監聽 firebase 推送通知 */
  const unsubscribe = token ? onMessage(messaging, observer) : null; // 註冊前景推送通知
  // TODO: onBackgroundMessage 不跟 /firebase/firebase-messaging-sw.js 衝突, 目前已知會說 onBackgroundMessage 重複註冊
  // FirebaseError: Messaging: This method is available in a service worker context. (messaging/only-available-in-sw).
  /** 停止監聽 firebase 背景推送通知 */
  // const bgUnsubscribe = token
  //   ? onBackgroundMessage(messaging, (payload) => {
  //       console.log(
  //         '🚀 ~ bPushLogic.ts:116 ~ bgUnsubscribe ~ payload:',
  //         payload
  //       );
  //     })
  //   : null; // 註冊背景推送通知

  // 监听token变化并注册到BPush
  const register = async () => {
    if (token.value !== null) {
      try {
        // 成功無返回值
        await BPush.registerToken({
          pushToken: token.value,
          appKey,
          bundleID,
          appVersion: version,
        });
        store.set('device-token', token.value);
      } catch (error) {
        console.error('Notification bPush register token error', error);
      }
    }
  };

  // 监听token变化并调用register
  watch(token, () => {
    console.log('Notification bPush token', token.value);
    register();
  });

  onUnmounted(() => {
    unsubscribe?.();
  });
};
