import axios from 'axios';
import { store } from '@/store/index';
import types from '@/store/mutation-types';
import { dateFormat } from '@/utils/util';
import { showToast, closeToast, ToastOptions, showLoadingToast } from 'vant';
import customStore from '@/request/http/store';
import { useCustomRouter } from '@/router/custRouter';
import router, { clearScrollMap } from '@/router';
import { showNbDialog } from '@/utils/dialog';
import i18n from '@/locales';
import cacheManager from '@/utils/cacheManager';
import appInfo from '@/utils/appInfo';
import { sendErrorGoogleTag, sendGoogleTag } from '@/scripts/tagEvent';
import { ROUTE_LOGIN, ROUTE_REGISTER } from '@/router/paths';

const hideLoading = () => {
  closeToast();
};

//请求失败后的错误统一处理
const errorHandle = (res: any, log: string) => {
  const data = res.data;
  const code = data.errorcode;
  const message = data.message;
  console.log(log + code + message);
  sendErrorGoogleTag({ error: message, error_code: code });
  if (100029 === code) {
    // 100029 ip黑名单
    store.commit(types.UPDATE_ERROR_IP, data.data[0]);
    return Promise.reject(data);
  } else if (100028 === code) {
    // 100028 系统维护
    store.commit(types.UPDATE_SYSTEM_MAINTENANCE);
    return Promise.reject(data);
  } else if (
    [
      101004, 101005, 101006, 101007, 101008, 101009, 101010, 101011, 101012,
      101013, 101014, 101015, 101021, 101022, 101023, 101028, 101033, 101034,
      101053, 101107, 101108, 101110,
    ].indexOf(code) >= 0
  ) {
    // 101004 登录状态已过期
    // 101005 登录状态已过期
    // 101006 登录状态已过期
    // 101007 登录状态已过期
    // 101008 登录状态已过期
    // 101009 登录状态已过期
    // 101010 登录状态已过期
    // 101011 登录状态已过期
    // 101012 登录状态已过期
    // 101013 登录状态已过期
    // 101014 登录状态已过期
    // 101015 帐号其他装置登录
    // 101021 请登录
    // 101022 登录状态已过期
    // 101023 用户身份错误
    // 101028 登录Token无效
    // 101033 系统強制退出登录
    // 101034 用户过期
    // 101053 试玩帐号过期
    // 101107 加入黑名单了
    // 101110 你已被系统登出，请稍后再试
    // 101108 你已经在其他地方登录。为确保账户安全，如果这不是你本人的操作，请尽快修改密码并联系客服。
    // 300057 资金密码错误次数上限，账号会被禁用
    const path = router.currentRoute.value.path;
    if (
      code === 101107 &&
      (path === ROUTE_LOGIN.path || path === ROUTE_REGISTER.path)
    ) {
      // 加黑名单要额外判断一下
      showToast(`${message}(${code})`);
    } else {
      showNbDialog({
        message: message,
      }).then(() => {
        // 接口报登录状态过期，如果是试玩账号，需要删除本地试玩账号缓存
        if (store.getters.userType.isGuest) {
          cacheManager.deleteCache(cacheManager.CacheKey.DEMO_ACCOUNT);
        }

        store.dispatch('logoutAction').then(() => {
          clearScrollMap();
          useCustomRouter(null)
            .replace('/')
            .then(() => {
              useCustomRouter(null).push(ROUTE_LOGIN.path);
            });
          // .then(() => {
          //   window.location.reload();
          // });
        });
      });
    }

    return Promise.reject({ ...data, loginError: true });
  } else if (
    [
      300052, 300054, 500007, 500013, 109020, 283000, 282002, 105016, 500010,
      900837, 1000001,
    ].indexOf(code) >= 0
  ) {
    // 这里的错误码不弹提示，会有指定的弹窗，例如资金验证
    // 300052 非绑定装置
    // 300054 请前往註册
    // 500007 无领取奖励
    // 500013 VIP未达领取条件
    // 109020 未开启自动转额
    // 283000 转盘已结束
    // 282002 任务关闭中
    // 105016 注册推广码上限
    // 500010 VIP功能未开启
    // 900837 充值需要绑定cpf
    // 1000001 红包已领取
    return Promise.reject(data);
  } else {
    if ([111036, 110009].indexOf(code) >= 0) {
      // 资金密码错误，使用ga追踪
      // 110009登录时非绑定设备输入
      // 111036解绑设备和提现时
      sendGoogleTag(
        'error_funds',
        {
          errorcode: code,
          params: res.config.data,
          url: res.config.url,
          header: res.config.headers,
        },
        'submit'
      );
    }
    showToast({
      duration: 2000,
      message: `${message}(${code})`,
      className: 'nb-api-error-toast',
    });
    return Promise.reject(data);
  }
};

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 10,
  withCredentials: true,
  params: {},
});

const regRex = /\/api\/User\/signUp|\/api\/User\/signIn/;
const captchaRegRex =
  /\/api\/User\/signUp|\/api\/User\/signIn|\/api\/General\/captcha/;
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  (config) => {
    if (config.url && !regRex.test(config.url)) {
      const token = customStore.get('token');
      if (token) {
        config.headers['Auth-Token'] = token;
      }
      const identity = store.getters.userType.value;
      if (identity) {
        config.headers['Auth-Identity'] = identity;
      }
    }

    const captchaToken = customStore.get('x-captcha-token');
    if (config.url && captchaRegRex.test(config.url) && captchaToken) {
      config.headers['x-captcha-token'] = captchaToken;
    }
    const deviceToken = customStore.get('device-token');
    if (deviceToken) {
      config.headers['device-token'] = deviceToken;
    }

    config.headers['Device-Id'] = appInfo.deviceTypeId;
    config.headers.Language = store.getters.languageId;
    if (appInfo.channelCode) {
      config.headers['x-channel-name'] = appInfo.channelCode;
    }
    // config.headers["x-channel-name"] =
    //   appInfo.channelCode ?? `wap-${document.domain}`;

    //打印请求数据
    let log =
      '\nC >>>>>> S  url:' +
      config.url +
      '\n' +
      dateFormat(new Date().getTime(), 'hh:mm:ss');
    log += '\n' + JSON.stringify(config.data, null, 4);
    console.log(log);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  (res) => {
    // 打印数据
    const log =
      '\nS >>>>>> C  url:' +
      res.config.url +
      '\n' +
      dateFormat(new Date().getTime(), 'hh:mm:ss');
    const authToken = res.headers['auth-token'];
    const authIdentity = res.headers['auth-identity'];
    const captchaToken = res.headers['x-captcha-token'];

    if (authToken) {
      customStore.set('token', authToken);
    }
    if (captchaToken) {
      customStore.set('x-captcha-token', captchaToken);
    }

    if (res.data.errorcode === 200) {
      const response = res.data.data;
      // 打印data数据
      console.log(log + JSON.stringify(response, null, 4));

      if (authIdentity) {
        customStore.set('identity', authIdentity);
      }

      return Promise.resolve(res.data);
    } else {
      hideLoading();
      return errorHandle(res, log);
    }
  },
  // 请求失败
  (error) => {
    console.log(JSON.stringify(error, null, 4));
    return Promise.reject(error.response);
  }
);

export default instance;

export const requestPost = (
  path: string,
  params: object | null,
  success?: (arg0: any, arg1?: string) => void, // arg1提供给请求成功的toast，例如重置密码
  failure?: ((error?: any) => void) | null,
  config?: any,
  loadingShow?: boolean,
  loadingConfig?: ToastOptions
) => {
  if (loadingShow) {
    showLoadingToast({
      teleport: '#app',
      duration: loadingConfig?.duration ?? 1000 * 10,
      message: loadingConfig?.message,
      overlay: true,
      forbidClick: true,
    });
  }
  instance
    .post(path, params, config)
    .then((response: any) => {
      if (loadingShow) {
        hideLoading();
      }
      if (success) success(response.data, response.message);
    })
    .catch((error) => {
      error500(error);
      if (failure) failure(error);
    });
};

export const requestPostPromise = (
  path: string,
  params: object | null,
  config?: any,
  loadingShow?: boolean,
  loadingConfig?: ToastOptions
) => {
  if (loadingShow) {
    showLoadingToast({
      teleport: '#app',
      duration: loadingConfig?.duration ?? 1000 * 10,
      message: loadingConfig?.message,
      overlay: true,
      forbidClick: true,
    });
  }

  return new Promise<any>((resolve, reject) => {
    instance
      .post(path, params, config)
      .then((response: any) => {
        if (loadingShow) {
          hideLoading();
        }

        resolve(response);
      })
      .catch((error) => {
        error500(error);
        reject(error);
      });
  });
};

const error500 = (error: any) => {
  if (error?.status === 500) {
    showToast({
      message: i18n.global.t('SERVICE_500_ERROR', {
        url: error?.config?.url,
      }),
    });
  }
};
